import navigation from './components/layout/navigation';
import responsiveVideos from './components/layout/responsiveVideos';
import scrollTo from './components/layout/scrollTo';
import sidebar from './components/layout/sidebar';
import pdfPreview from './components/layout/pdf-preview';
import progressBar from './components/layout/progressBar';
import validation from './components/forms/validation';
import gaTracking from './components/tracking/gatracking';
import animations from './animations/animations';
import sliders from './components/sliders/sliders';
import podcast from './components/podcast/podcast';

require('./components/layout/resizeSensor');
require('./utils/nativeConsole');
require('waypoints/lib/jquery.waypoints');
require('waypoints/lib/shortcuts/sticky');
require('smooth-scroll');
require('progressbar.js');
require('lightgallery');
require('lg-fullscreen');
require('lg-zoom');
// require('jquery-touchswipe/jquery.touchSwipe'); // use with fancybox, cycle2, etc

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
navigation();
responsiveVideos();
scrollTo();
sidebar();
progressBar();
animations();
pdfPreview();
sliders();
podcast();

const readMore = document.querySelectorAll('.js-read-more');
if (readMore) {
  readMore.forEach((more) => {
    more.addEventListener('click', () => {
      const moreContent = more.previousElementSibling.querySelector('div');
      moreContent.classList.toggle('hide');

      if (more.querySelector('span').innerHTML === 'En savoir plus') {
        more.querySelector('span').innerHTML = 'En savoir moins';
      } else {
        more.querySelector('span').innerHTML = 'En savoir plus';
      }
    });
  });
}

// Forms
validation();

// Tracking
gaTracking();
